body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

html {
  font-size: 62.5%;
  font-family: Roboto, sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

table td {
  text-align: left;
}

.no-wrap {
  white-space: nowrap;
}

/* Header and Side Shelf */
.header {
  border-bottom: 1px solid #e2e8f0;
  overflow: visible;
  position: relative;
  z-index: 1099;
  background-color: white;
  box-shadow: 0 1px 15px -10px rgba(0 0 0 75%);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  height: 55px;
  z-index: 401;
}

.checkbox {
  vertical-align: middle;
  position: relative;
  margin-right: 4px;
  bottom: 1px;
}

.tab-cell {
  display: table-cell;
  height: 15px;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 500;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  margin-left: 5rem;
}

.nav-link {
  font-size: 1.6rem;
  font-weight: 400;
  color: #475559;
}

.nav-link:hover {
  color: #482ff7;
}

.nav-logo {
  font-size: 2.1rem;
  font-weight: 500;
  color: rgb(34 94 34);
}

.hamburger {
  position: absolute;
  top: 14px;
  right: 15px;
  z-index: 1099;
}

.hamburger button {
  background-color: #f7f2f2;
  border: none;
  cursor: pointer;
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0 0 0 30%);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(38 38 38);
  outline: 0 solid slategrey;
}

.mobile-shelf-container {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 200px;
  z-index: 1099;
  background-color: white;
  overflow-y: auto;
  box-shadow: 0 -5px 72px -40px #000;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.mobile-shelf-container.moving {
  transition: all 0.25s;
}

.mobile-shelf-handle-container {
  margin-bottom: 8px;
  background-color: white;
  padding: 12px;
  touch-action: none;
}

.mobile-shelf-handle {
  width: 50px;
  height: 6px;
  margin: auto;
  opacity: 0.6;
  background-color: lightslategray;
  border-radius: 12px;
  touch-action: none;
}

.left-shelf-container {
  position: absolute;
  top: 56px;
  padding-top: 75px;
  margin-bottom: 36px;
  left: -225px;
  height: 100%;
  background-color: white;
  width: 10px;
  z-index: 1096;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.left-shelf-container.active {
  width: 330px;
  left: 0;
}

.left-shelf-content {
  padding: 2px;
  margin-top: 15px;
  margin-bottom: 55px;
}

.left-shelf-content table {
  width: 100%;
}

.left-shelf-content tr {
  height: 25px;
  border: 1px solid black;
}

.left-shelf-content td {
  border-bottom: 1px solid black;
  text-align: center;
}

.right-shelf-container {
  position: absolute;
  top: 56px;
  right: -100px;
  background-color: white;
  z-index: 2000;
  height: 100%;
  width: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
}

.right-shelf-container.active {
  right: 1px;
  display: block;
  height: 100%;
  width: 330px;
}

.right-shelf-content {
  padding: 2rem;
}

.invisible-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
}

/* Filters inside Shelf */
.filter-table {
  width: 100%;
  margin: auto;
  margin-bottom: 36px;
  padding: 4px 12px;
  padding-bottom: 36px;
}

.align-left {
  text-align: left;
}

.button {
  border-radius: 12px;
  background-color: rgb(50 50 203);
  color: white;
  border: none;
  padding: 12px;
  font-weight: 600;
  cursor: pointer;
  margin: 1em;
}

.filter-table button:hover {
  background-color: rgb(50 50 203);
  transition: 0.5s;
}

.filter-image {
  vertical-align: middle;
  margin-bottom: 4px;
  display: inline-block;
}

.filter-table h2 {
  display: inline-block;
}

.filter-table label {
  font-size: 14px;
}

.option-label {
  font-weight: bold;
}

.filter-type {
  font-weight: bold;
  padding: 20px 5px 0;
  margin-top: 5px;
}

/* Popup Content */
blockquote {
  margin: 10px 10px 5px;
}

.image-background {
  background-color: rgb(34 29 29);
}

.shown-image {
  display: block;
  margin: auto;
}

.park-image {
  max-width: 100%;
  max-height: 300px;
}

.hide-image {
  display: none;
}

.image-title {
  color: white;
  display: block;
  padding: 4px;
  letter-spacing: 1px;
}

.image-info {
  color: white;
  padding: 8px;
  letter-spacing: 1px;
}

.image-dots {
  width: 75%;
  margin: auto;
  padding: 4px;
  color: white;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.image-dot {
  height: 10px;
  width: 10px;
  background-color: auto;
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
}

.image-dot.active {
  background-color: white;
}

/* .image-button {
  width: 35px;
  border: none;
  cursor: pointer;
  background: none;
} */

.relative {
  position: relative;
}

.image-box {
  position: absolute;
  top: 40%;
  z-index: 1099;
  height: 45px;
  width: 45px;
  cursor: pointer;
  border-radius: 50px;
}

.image-button {
  position: relative;
  top: 8px;
  border: none;
  background: white;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.image-box.left {
  left: 10px;
}

.image-box.right {
  right: 10px;
}

.left-space > * {
  margin-left: 10px;
}

.leaflet-container {
  position: relative;
  left: 0;

  /* transition: all 0.1s ease-in-out; */
}

.leaflet-container.active {
  left: 175px !important;
}

.leaflet-popup-content-wrapper {
  width: 450px;
  height: 350px;
}

.leaflet-popup-content {
  position: relative;
  width: auto !important;
  overflow-y: scroll;
  height: 300px;
}

.inner-popup-header {
  position: fixed;
  background-color: white;
  display: flex;
  top: 12px;
}

.inner-popup-content {
  margin-top: -2vh;
}

.park-title-text {
  flex-grow: 2;
  width: 100%;
}

/* Park Outlines */
.outline {
  opacity: 0;
  visibility: hidden;
}

.x {
  display: inline-block;
  top: 10px;
  right: 4px;
  position: absolute;
  cursor: pointer;
}

.map-header {
  letter-spacing: 1.2px;
  font-size: 20px;
}

/* Park Search */
.park-search {
  position: relative;
  width: 280px;
}

.park-search-box input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
  height: 35px;
  width: 200px;
  text-align: left;
}

.park-search-box input:disabled {
  background-color: rgba(230 227 227 35%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  cursor: not-allowed;
}

.park-search-box {
  position: absolute;
  top: 70px;
  left: 17px;
  background-color: white;
  z-index: 1097;
  line-height: 10px;
  border-radius: 10px;
  box-shadow: 5px 5px 13px -10px #000;
  padding: 8px;
}

.search-icon {
  display: inline-block;
  top: 10px;
  left: 4px;
  position: absolute;
}

.park-search-results {
  position: absolute;
  z-index: 401;
}

.park-search-results table {
  background-color: white;
  text-align: left;
  width: 280px;
}

.park-search-results tr {
  background-color: white;
  cursor: pointer;
  height: 30px;
  width: 100%;
  padding: 2px;
  font-size: small;
  font-weight: 500;
}

.park-search-results td {
  border-bottom: 1px solid rgb(64 135 64 / 48%);
  padding: 4px;
}

.park-search-results tr:last-child td {
  border: none;
}

.park-search-results td:last-child:hover {
  background-color: rgb(94 121 85);
  color: white;
  border-radius: 8px;
}

.park-search-results .non-hover {
  cursor: auto;
}

.park-search-results-icon {
  width: 25px;
}

/* Sets Zoom Control */
.leaflet-top,
.leaflet-bottom {
  position: fixed !important;
}

.leaflet-control-zoom {
  margin-bottom: 72px !important;
}

.copyright-container {
  position: fixed;
  bottom: 1px;
  left: 0;
  z-index: 1000;
  font-weight: bold;
  background: rgba(255 255 255 7%);
  padding: 0 5px;
}

@media only screen and (max-width: 640px) {
  .right-shelf-container.active {
    width: 55%;
  }

  .right-shelf-content {
    padding: 4px;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0 0 0 5%);
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: 1099;
  }
}
